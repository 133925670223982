import React, { useEffect, useState } from 'react';
import './Login.css'

function OTPVerificationSite({ apiURL, onOTPVeryfied, user, isPasswordReset }) {
  const inputs = ["input1", "input2", "input3", "input4", "input5", "input6"];
  const [errorText, setErrorText] = useState(""); // Add state for error text
  useEffect(() => {
    initializeInputs();
  });

  function initializeInputs() {
    inputs.map((id) => {
      const input = document.getElementById(id);
      addListener(input);
    });
  }

  function handle2FAButtonClick() {
    const input1 = document.getElementById("input1");
    const input2 = document.getElementById("input2");
    const input3 = document.getElementById("input3");
    const input4 = document.getElementById("input4");
    const input5 = document.getElementById("input5");
    const input6 = document.getElementById("input6");
    const otp_token = input1.value + input2.value + input3.value + input4.value + input5.value + input6.value;
    // check if api token is valid
    // if valid, redirect to account landing page
    // if not valid, display error message

    // make api call
    var apiLink = !isPasswordReset ?  apiURL + "/verify_otp_token" : apiURL + "/verify_otp_password_reset";

    fetch(apiLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: user.email,
        otp: otp_token,
      }),
    }).then((response) => {
      // process response
      // console.log(response);
      if (response.status !== 200) {
        // error
        response.json().then((data) => {
          console.log(data);
          alert("Error: " + response.status);
        });
      } else {
        // success
        response.json().then((data) => {
          console.log(data);
          // update token
          localStorage.setItem("token", data.token);
          onOTPVeryfied();
        });
      }
    });
  }

  function addListener(input) {
    console.log(input);
    input.addEventListener("keyup", event => {
      const n = input.nextElementSibling;
      if (n) n.focus();
      const key = event.key; // const {key} = event; ES6+
      if (key === "Backspace" || key === "Delete") {
        const prev = input.previousElementSibling;
        if (prev) prev.focus();
      }
    });
    input.addEventListener("keydown", event => {
      const key = event.key; // const {key} = event; ES6+
      if (key === "Enter") {
        handle2FAButtonClick();
      }
    });
  }
  return (
    <div className='login-content'>
      {isPasswordReset && <div className="title">Email Verification</div>}
      {isPasswordReset && <div className="title">Verification Code</div>}
      {isPasswordReset && <p>We have sent a verification code to your mail address</p>}
      {!isPasswordReset && <div className="title">Two Factor Authentication</div>}
      {!isPasswordReset && <div className="title">Verification Code</div>}
      {!isPasswordReset && <p>Please enter the the Code displayed in your Athenticator App</p>}
      <div id='inputs'>
        <input id='input1' className='otp_input' type='text' maxLength="1" />
        <input id='input2' className='otp_input' type='text' maxLength="1" />
        <input id='input3' className='otp_input' type='text' maxLength="1" />
        <input id='input4' className='otp_input' type='text' maxLength="1" />
        <input id='input5' className='otp_input' type='text' maxLength="1" />
        <input id='input6' className='otp_input' type='text' maxLength="1" />
      </div>
      {errorText && <p>{errorText}</p>} {/* Display error text if available */}
      <button
        className='login-button'
        onClick={() => {
          handle2FAButtonClick()
        }
        }>Submit</button>
    </div>
  );
}

export default OTPVerificationSite;
