import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { FaPauseCircle, FaFileAudio, FaPlayCircle } from 'react-icons/fa';
import { Tooltip } from "react-tooltip";
import "quill/dist/quill.bubble.css";


const AudioController = forwardRef(({ textToRead }, ref) => {
    //console.log(textToRead)
    const synth = window.speechSynthesis;
    const [utterance, setUtterance] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState("idle");

    useEffect(() => {
        if (textToRead) {
            var new_utterance = new SpeechSynthesisUtterance(textToRead)
            new_utterance.onend = () => {
                setIsSpeaking("idle");
            };
            new_utterance.onerror = () => {
                setIsSpeaking("idle");
            };
            setUtterance(new_utterance);
        }
    }, [textToRead]);

    useImperativeHandle(ref, () => ({
        autoReadAlaoud() {
            console.log("Reading aloud:", textToRead);
            handleSpeak();
        }
    }));

    function handleSpeak() {
        console.log("Speak clicked Text to speak:", textToRead)
        setIsSpeaking("speaking");
        if (synth.speaking) {
            synth.cancel();
        }
        speechSynthesis.speak(utterance);
    };

    return (
        <div ref={ref} data-tooltip-id={"AudiocontrollerTooltip"} >
            {isSpeaking === "idle" ?
                <FaFileAudio style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={handleSpeak}
                /> : isSpeaking === "speaking" ?
                    <FaPauseCircle style={{ margin: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            setIsSpeaking("paused");
                            synth.pause();
                        }}
                    /> :
                    <FaPlayCircle style={{ margin: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            setIsSpeaking("speaking")
                            synth.resume();
                        }}
                    />
            }
            <Tooltip id={"AudiocontrollerTooltip"} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Here you can play this message as audio. In the Account Settings you can set playing audios to default to play every message as soon as its completed.
                </div>
            </Tooltip>
        </div>
    );
});

export default AudioController;

