import { useEffect, useState, useRef } from 'react';
import './Impressum.css'
import { Link } from 'react-router-dom';
import { CompanyData, UserData } from '../Settings/SettingsTypedefs';
import privacyprompt from './../../assets/privacyprompt.svg';
import { FaAngleDown, FaAngleUp, FaUser } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faArrowRightToBracket, faCommentDots, faGlobe, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight, { Height } from "react-animate-height"

function APISetupDropdown({ userData, companyData, isAlive, isInitialized, ...props }: { userData: UserData | null, companyData: CompanyData | null, isAlive: boolean, isInitialized: boolean }) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);


    return (
        <div className="api-dropdown-container" >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                    setExpanded(!expanded)
                }}>
                <FontAwesomeIcon icon={faGlobe} style={isAlive && isInitialized ? { marginRight: "5px", color: "green" } : isAlive && !isInitialized ? { marginRight: "5px", color: "yellow" } : { marginRight: "5px", color: "red" }} />
                <div style={{ marginRight: "5px" }}>
                    {companyData ? companyData.companyName : "No API Connected"}
                </div>
                {!expanded ?
                    <FaAngleDown style={{ marginRight: "5px" }} />
                    :
                    <FaAngleUp style={{ marginRight: "5px" }} />
                }
            </div>
            <AnimateHeight
                {...props}
                height={height}
                contentClassName="animate-heigth-content"
                contentRef={contentDiv}
                disableDisplayNone
                style={{ position: "relative" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {expanded &&

                        <div ref={contentDiv}
                            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", lineBreak: "anywhere" }}>
                            <div className={isAlive && isInitialized ? 'api-hint-ok' : isAlive ? 'api-hint-init-needed' : 'api-hint-dead'}>
                                {isAlive && isInitialized ?
                                    <div>
                                        <div>
                                            {'API-URL:' + companyData?.pp_api_url}
                                        </div>
                                        <div>
                                            Connection: Alive
                                        </div>
                                        <div>
                                            Initialization: Done
                                        </div>
                                    </div>
                                    :
                                    isAlive ?
                                        <div>
                                            <div>
                                                {'API-URL:' + companyData?.pp_api_url}
                                            </div>
                                            <div>
                                                Connection: Alive
                                            </div>
                                            <div>
                                                Initialization: Neccessary
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div>
                                                {'API-URL:' + companyData?.pp_api_url}
                                            </div>
                                            <div>
                                                Connection: Missing
                                            </div>
                                            <div>
                                                Initialization: Unknown
                                            </div>
                                        </div>
                                }
                            </div>
                            <Link className='account-dropdow-button'
                                to={"/api_setup"}>
                                Configure API Settings
                                <FontAwesomeIcon icon={faNetworkWired} />
                            </Link>
                        </div>

                    }
                </div>
            </AnimateHeight>

        </div >
    );
}

export default APISetupDropdown;