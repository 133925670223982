import { FaAngleDown, FaAngleUp, FaTrashAlt, FaCheck, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Switch from "react-switch"
import { LLMAPIData, OptionType } from "../SettingsTypedefs";
import { Tooltip } from 'react-tooltip';
import { Circles } from 'react-loader-spinner';
import LLMIcon from "../../../Components/Icons/LLMIcon";
import "./../Settings.css"

export default function APITile({ apiAccessData, apiURL, onAPIAccessChanged, isAdminView, securityLevelOptions, isMobile, ...props }:
    { apiAccessData: LLMAPIData, apiURL: string, onAPIAccessChanged: Function, isAdminView: Boolean, securityLevelOptions: OptionType[], isMobile: boolean }) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState<Height>('auto');
    const [editedAPIName, setEditedAPIName] = useState(apiAccessData ? apiAccessData.api_name ? apiAccessData.api_name : "" : "");
    const [editedAPIModelName, setEditedAPIModelName] = useState(apiAccessData ? apiAccessData.api_model_name ? apiAccessData.api_model_name : "" : "");
    const [editedAPIUrl, setEditedAPIUrl] = useState(apiAccessData ? apiAccessData.api_url ? apiAccessData.api_url : "" : "");
    const [editedAPIKey, setEditedAPIKey] = useState(apiAccessData ? apiAccessData.api_key ? apiAccessData.api_key : "" : "");
    const [editedSecurityLevel, setEditedSecurityLevel] = useState(apiAccessData ? apiAccessData.security_level ? apiAccessData.security_level : 1 : 1);
    const [editedContingentType, setEditedContingentType] = useState(apiAccessData ? apiAccessData.contingentType ? apiAccessData.contingentType : false : false);
    const [editedIconNumber, setEditedIconNumber] = useState(apiAccessData ? apiAccessData.icon_number ? apiAccessData.icon_number : 0 : 0)

    const [isIconSelectionMode, setIsIconSelectionMode] = useState(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    const [showAPIKey, setShowAPIKey] = useState(false);
    const [showContingentTypeHint, setShowContingentTypeHint] = useState(false);
    const [checkingStatusAfterUpdate, setCheckingStatusAfterUpdate] = useState(false)
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const target = event.target as Node;
            if (popupRef.current && !popupRef.current.contains(target)) {
                setIsIconSelectionMode(false);
            }
        };

        if (isIconSelectionMode) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isIconSelectionMode]);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);


    useEffect(() => {
        console.log("API Access Data in Tile:", apiAccessData)
        if (apiAccessData) {
            setEditedAPIName(apiAccessData.api_name ? apiAccessData.api_name : "");
            setEditedAPIKey(apiAccessData.api_key ? apiAccessData.api_key : "");
            setEditedAPIModelName(apiAccessData.api_model_name ? apiAccessData.api_model_name : "");
            setEditedAPIUrl(apiAccessData.api_url ? apiAccessData.api_url : "");
            setEditedContingentType(apiAccessData.contingentType ? apiAccessData.contingentType : false)
        }
        setCheckingStatusAfterUpdate(false);
    }, [apiAccessData])

    useEffect(() => {
        console.log("Options in TIle:", securityLevelOptions)
    }, [securityLevelOptions])

    function checkAPIAccessEdited() {
        if (!apiAccessData) {
            return false;
        }
        return editedAPIName !== apiAccessData.api_name || editedAPIUrl !== apiAccessData.api_url || editedAPIKey !== apiAccessData.api_key || editedAPIModelName !== apiAccessData.api_model_name || editedContingentType !== apiAccessData.contingentType || editedIconNumber !== apiAccessData.icon_number;
    }

    async function updateAPIAccess() {
        console.log("Updating API access");
        setCheckingStatusAfterUpdate(true)
        try {
            const response = await fetch(
                apiURL + "/update_llm_api",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "api_id": apiAccessData.api_id,
                            "api_name": editedAPIName,
                            "api_model_name": editedAPIModelName,
                            "is_company_api": apiAccessData.is_company_api,
                            "api_url": editedAPIUrl,
                            "api_key": editedAPIKey,
                            "security_level": editedSecurityLevel,
                            "contingentType": editedContingentType,
                            "icon_number": editedIconNumber
                        }
                    )
                }
            );

            if (response.status !== 200 && response.status !== 409) {
                console.log("Response status: " + response.status);
            } else if (response.status === 409) {
                setShowContingentTypeHint(true)
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
            } else {
                setShowContingentTypeHint(false)
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
                //setExpanded(false);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function deleteAPIAccess() {
        console.log("Deleting API access");
        try {
            const response = await fetch(
                apiURL + "/remove_llm_api",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "api_id": apiAccessData.api_id,
                            "api_name": editedAPIName,
                            "api_model_name": editedAPIModelName,
                            "is_company_api": apiAccessData.is_company_api,
                            "api_url": editedAPIUrl,
                            "api_key": editedAPIKey,
                            "security_level": editedSecurityLevel,
                            "contingentType": false,
                            "icon_number": editedIconNumber
                        }
                    )
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setExpanded(false);
                setTimeout(() => {
                    onAPIAccessChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }


    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName="animate-heigth-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ width: "100%", alignSelf: "center", alignItems: "center", justifyContent: "center", justifySelf: "center", overflow: "visible" }}>
            <div className="api-tile" ref={contentDiv}>
                <div
                    style={(expanded || !isAdminView) ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px' } :
                        { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px', cursor: "pointer" }}
                    onClick={(expanded || !isAdminView) ? () => { console.log("Empty Function") } : () => {
                        setExpanded(!expanded);
                    }}
                >
                    {!expanded ?
                        <div style={{ width: '70%', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ marginRight: "20px", flex: "4" }}>{editedAPIName}</div>
                            <div style={{ flex: "1" }}>
                                <LLMIcon
                                    iconNumber={editedIconNumber}
                                    maxHeight="30px"
                                    maxWidth="30px"
                                />
                            </div>
                        </div>
                        :
                        <div style={{ width: '70%', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div style={isMobile ? { marginLeft: "5px", fontWeight: 'normal', width:"40%" } : 
                                { marginLeft: "5px", fontWeight: 'bold', width:"40%" }}>
                                LLM Name: 
                            </div>
                            <input
                                className="settings-input"
                                value={editedAPIName}
                                placeholder="New API Name"
                                onChange={(e) => {
                                    setEditedAPIName(e.target.value);
                                }}
                            />
                            <div data-tooltip-id="LLMNameTooltip">
                                <FaInfoCircle />
                                <Tooltip id="LLMNameTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw" }}>
                                        The name under which this API will be displayed to your Users and in further settings.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    }
                    <div style={{ width: "15%" }}>
                        {!checkingStatusAfterUpdate ?
                            <div style={apiAccessData.is_alive ? { color: 'green' } : { color: 'red' }}>
                                Status: {apiAccessData.is_alive ? "Alive" : "Dead"}
                            </div> :
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Circles
                                    height="20"
                                    width="20"
                                    color="var(--text-icons)"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                        }
                    </div>
                    {isAdminView && <div style={{ marginRight: '5px', cursor: 'pointer' }}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        {expanded ? <FaAngleUp /> : <FaAngleDown />}
                    </div>}
                </div>
                {expanded && <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                    <div style={isMobile ? { display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" } :
                        { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%', padding: '5px', position: "relative" }}>
                            <div className={`api-tile-input-row ${isMobile?"mobile" : ""}`}>
                                <div>
                                    API URL:
                                </div>
                                <input
                                    className="settings-input"
                                    value={editedAPIUrl}
                                    placeholder="New API URL"
                                    onChange={(e) => {
                                        setEditedAPIUrl(e.target.value);
                                    }}
                                />
                                <div data-tooltip-id="LLMURLTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMURLTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The URL where the anonymized prompts will be send to. Example for ChatGPT: https://api.openai.com/v1/chat/completions
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile?"mobile" : ""}`}>
                                <div>
                                    API Model Name:
                                </div>
                                <input
                                    className="settings-input"
                                    value={editedAPIModelName}
                                    placeholder="New API Model Name"
                                    onChange={(e) => {
                                        setEditedAPIModelName(e.target.value);
                                    }}
                                />
                                <div data-tooltip-id="LLMModelNameTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMModelNameTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Most Large Language Model provider give access to different models which can be specified by the model name. Example for ChatGPT: gpt-3.5-turbo
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile?"mobile" : ""}`}>
                                <div>
                                    API Key:
                                </div>
                                <input
                                    type={showAPIKey ? "text" : "password"}
                                    className="settings-input"
                                    value={editedAPIKey}
                                    placeholder="New API Key"
                                    onChange={(e) => {
                                        setEditedAPIKey(e.target.value);
                                    }}
                                />
                                <FaEyeSlash
                                    onClick={() => {
                                        setShowAPIKey(!showAPIKey);
                                    }}
                                    style={{ marginRight: "5px" }}
                                />
                                <div data-tooltip-id="LLMKeyTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMKeyTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            The API can key can be empty for APIs without authorization. If a value is assigned it can only be accessed by system Admins.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile?"mobile" : ""}`}>
                                <div>
                                    Security Level:
                                </div>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: "var(--background)",
                                            color: "var(--text-icons)",
                                            zIndex: 10
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 110
                                        }),
                                        group: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            zIndex: 110
                                        }),
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)"
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: "var(--background)",
                                        }),
                                        multiValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                            zIndex: 10
                                        }),
                                        multiValueLabel: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "var(--text-icons)",
                                            backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                        }),
                                        container: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "100%",
                                            margin: "5px",
                                        })
                                    }}
                                    options={securityLevelOptions}
                                    onChange={(selectedOptions) => {
                                        console.log("selectedOptions:", selectedOptions);
                                        setEditedSecurityLevel(selectedOptions!.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                    value={securityLevelOptions.find(ele => ele.value === editedSecurityLevel)}
                                    isMulti={false}
                                />
                                <div data-tooltip-id="LLMSecLvlTooltip">
                                    <FaInfoCircle />
                                    <Tooltip id="LLMSecLvlTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Every Security Level is connected to rules and policys. If no Security Level is assigned the Default Security level will be selected. Every prompt send to this APIs will be checked with the rules and policys assigned to the Security Level.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={`api-tile-input-row ${isMobile?"mobile" : ""}`}>
                                <div>
                                    Contingent Type:
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                    <div style={{ flex: "1" }}>
                                        <Switch
                                            checked={editedContingentType}
                                            onChange={() => {
                                                setEditedContingentType(!editedContingentType)
                                            }}
                                        />

                                        {showContingentTypeHint &&
                                            <div style={{ fontSize: "12px", color: "yellow" }}>
                                                Update failed because there is a Pricing Preset with a different Contingent Type connected to this LLM API
                                            </div>}
                                    </div>
                                    { !isMobile &&
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>
                                            {editedContingentType ? "Contingent per token" : "Contingent per request"}
                                        </div>
                                    }
                                    <div data-tooltip-id="LLMConTypeTooltip">
                                        <FaInfoCircle />
                                        <Tooltip id="LLMConTypeTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The contingenttype is relevant for the usage calculation of users with restrained API access. Most Large Language Model Provider calculate theire usage per Token. Activated means contingents per token deactivated per request.
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {!apiAccessData.is_alive &&
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", margin: "15px", color: "yellow" }}>
                                    {"Connection Problem: " + apiAccessData.connection_hint}
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1', height: "100%", marginRight: "20px", width:"100%" }}>
                            <LLMIcon
                                iconNumber={editedIconNumber}
                                maxHeight="100px"
                                maxWidth="100px"
                            />
                            <div
                                className={`settings-button ${isMobile ? "mobile" : ""}`}
                                onClick={() => {
                                    setIsIconSelectionMode(true);
                                }}>
                                Change Icon
                            </div>
                            {
                                isIconSelectionMode &&
                                <div style={{ position: "relative", overflow: "visible" }}>
                                    <div style={{
                                        position: "absolute",
                                        padding: "20px",
                                        border: "3px solid var(--text-icons-3)",
                                        borderRadius: "15px",
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "var(--background)",
                                        zIndex: "111",
                                        overflow: "auto",
                                        right: "-100px",
                                        top: "0"
                                    }}
                                        ref={popupRef}>
                                        {[0, 1, 2, 3].map(index =>
                                            <div
                                                key={index}
                                                style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ margin: "10px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setEditedIconNumber(index * 2)
                                                        setIsIconSelectionMode(false);
                                                    }}>
                                                    <LLMIcon
                                                        iconNumber={index * 2}
                                                        maxHeight="50px"
                                                        maxWidth="50px"
                                                    />
                                                </div>
                                                <div style={{ margin: "10px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setEditedIconNumber(index * 2 + 1)
                                                        setIsIconSelectionMode(false);
                                                    }}>
                                                    <LLMIcon
                                                        iconNumber={index * 2 + 1}
                                                        maxHeight="50px"
                                                        maxWidth="50px"
                                                    />
                                                </div>

                                            </div>
                                        )}
                                        Select your icon
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', justifySelf: "center", alignSelf: "center", width: "100%" }}>
                        <div
                            className={`settings-button ${isMobile ? "mobile" : ""} ${checkAPIAccessEdited() ? "disbaled" : ""}`}
                            onClick={checkAPIAccessEdited() ? () => {
                                updateAPIAccess();
                            } : () => {
                                console.log("No changes to update")
                            }}
                        >
                            Confirm Changes
                            <FaCheck className="confirm-icon" />
                        </div>

                        <div
                            className={`settings-button ${isMobile ? "mobile" : ""}`}
                            style={{ color: "red", borderColor: "red" }}
                            onClick={() => {
                                deleteAPIAccess();
                            }}
                        >
                            Delete
                            <FaTrashAlt className="cancel-delete-icon" />
                        </div>

                    </div>
                </div>}
            </div>
        </AnimateHeight>
    );
}
