import { FaUser, FaUsers, FaIndustry, FaKey, FaPalette, FaCommentDollar, FaUserFriends, FaShieldAlt, FaCommentsDollar, FaGavel } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSection, faTableCellsColumnLock } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSection)
library.add(faTableCellsColumnLock)
export default function SettingsSelection({ user, onSettingsModeChanged, settingsMode }) {
    return (
        <div className="settings-container">
            <div className="settings-selection">
                <div className="settings-selection-content">
                    <div style={{ margin: '50px' }}>
                    </div>
                    <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("account");
                        }}
                        style={settingsMode === "account" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaUser style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Account
                        </div>
                    </div>
                    <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("company");
                        }}
                        style={settingsMode === "company" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaIndustry style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Company
                        </div>
                    </div>
                    {user.userRole === 1 && <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("company_accounts");
                        }}
                        style={settingsMode === "company_accounts" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaUsers style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Company Accounts
                        </div>
                    </div>}
                    {user.userRole === 1 && <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("user_groups");
                        }}
                        style={settingsMode === "user_groups" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaUserFriends style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            User Groups
                        </div>
                    </div>}
                    <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("api");
                        }}
                        style={settingsMode === "api" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaKey style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            API Access
                        </div>
                    </div>
                    {user.userRole === 1 && <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("price_contol");
                        }}
                        style={settingsMode === "price_contol" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaCommentsDollar style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Price Control
                        </div>
                    </div>}
                    {user && user.userRole <= 1 &&
                        <div
                            className="settings-mode-tile"
                            onClick={() => {
                                onSettingsModeChanged("secure_data");
                            }}
                            style={settingsMode === "secure_data" ? { backgroundColor: 'var(--background-2)' } : {}}>
                            <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FontAwesomeIcon icon="fa-solid fa-table-cells-column-lock" style={{ margin: '8px' }} />
                            </div>
                            <div style={{ textAlign: "start", flex: "2" }}>
                                Secure Data
                            </div>
                        </div>}
                    {user && user.userRole <= 1 &&
                        <div
                            className="settings-mode-tile"
                            onClick={() => {
                                onSettingsModeChanged("security_levels");
                            }}
                            style={settingsMode === "security_levels" ? { backgroundColor: 'var(--background-2)' } : {}}>
                            <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FaShieldAlt style={{ margin: '8px' }} />
                            </div>
                            <div style={{ textAlign: "start", flex: "2" }}>
                                Security Levels
                            </div>
                        </div>}
                    {user && user.userRole <= 1 &&
                        <div
                            className="settings-mode-tile"
                            onClick={() => {
                                onSettingsModeChanged("security_rules");
                            }}
                            style={settingsMode === "security_rules" ? { backgroundColor: 'var(--background-2)' } : {}}>
                            <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FontAwesomeIcon icon="fa-solid fa-section" style={{ margin: '8px' }} />
                            </div>
                            <div style={{ textAlign: "start", flex: "2" }}>
                                Security Rules
                            </div>
                        </div>}
                    {user && user.userRole <= 1 &&
                        <div
                            className="settings-mode-tile"
                            onClick={() => {
                                onSettingsModeChanged("security_policys");
                            }}
                            style={settingsMode === "security_policys" ? { backgroundColor: 'var(--background-2)' } : {}}>
                            <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <FaGavel style={{ margin: '8px' }} />
                            </div>
                            <div style={{ textAlign: "start", flex: "2" }}>
                                Security Policys
                            </div>
                        </div>}
                    <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("color");
                        }}
                        style={settingsMode === "color" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaPalette style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Color config
                        </div>
                    </div>
                    <div
                        className="settings-mode-tile"
                        onClick={() => {
                            onSettingsModeChanged("chat");
                        }}
                        style={settingsMode === "chat" ? { backgroundColor: 'var(--background-2)' } : {}}>
                        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FaCommentDollar style={{ margin: '8px' }} />
                        </div>
                        <div style={{ textAlign: "start", flex: "2" }}>
                            Chat config
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
