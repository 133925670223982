import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons";
import { faMicrophone, faStop } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
library.add(faMicrophone);
library.add(faStop)

export default function ChatInput({ queryText, onChatInputChanged, onChatInputSubmit }) {
    const { transcript, resetTranscript, listening } = useSpeechRecognition();
    const chatInputRef = useRef(null); // Use useRef instead of useState

    useEffect(() => {
        console.log("Transcript:", transcript)
        if (transcript) {
            insertTextAtCursor(transcript);
            resetTranscript();
        }
    }, [transcript]);

    const insertTextAtCursor = (text) => {
        const textarea = chatInputRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const textBefore = queryText.slice(0, start);
        const textAfter = queryText.slice(end);
        onChatInputChanged(textBefore + text +" "+ textAfter);
        textarea.selectionStart = textarea.selectionEnd = start + text.length;
        textarea.focus();
    };

    const handleTextareaChange = (e) => {
        onChatInputChanged(e.target.value);
        const textarea = e.target;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey) {
                const textarea = e.target;
                const cursorPosition = textarea.selectionStart;
                const textBeforeCursor = queryText.slice(0, cursorPosition);
                const textAfterCursor = queryText.slice(cursorPosition);
                onChatInputChanged(textBeforeCursor + "\n" + textAfterCursor);
                setTimeout(() => {
                    textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1;
                }, 0);
            } else if (queryText.trim() !== "") {
                onChatInputSubmit();
                const textarea = e.target;
                textarea.style.height = "auto";
                textarea.style.height = textarea.scrollHeight + "px";
            }
        }
    };

    function startListenin() {
        SpeechRecognition.startListening({  continuous: true});
    }

    function stopListenin() {
        SpeechRecognition.stopListening();
    }

    return (
        <div className="bottom-container" id="chatInputContainer">
            <div className="chat-input-container">
                <textarea
                    id="chatInput"
                    ref={chatInputRef}
                    className="new_privat_data_input"
                    style={{ height: "100%" }}
                    type="text"
                    placeholder="Enter Text to Filter for Private Data"
                    value={queryText}
                    maxLength={50000}
                    onChange={handleTextareaChange}
                    onKeyPress={handleKeyPress}
                />
                {listening ?
                    <button className="submitt-button" onClick={stopListenin}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            {"("}
                            <FontAwesomeIcon icon={faMicrophone} size="xl" style={{marginRight:"5px"}}/>
                            <FontAwesomeIcon icon={faStop} size="xl" />
                            {")"}
                        </div>
                    </button>
                    :
                    <button className="submitt-button" onClick={startListenin}>
                        <FontAwesomeIcon icon={faMicrophone} size="xl" />
                    </button>
                }

                <button className="submitt-button" onClick={onChatInputSubmit}>
                    <FontAwesomeIcon icon={faShareFromSquare} size="xl" />
                </button>
            </div>
        </div>
    );
}
