import { useEffect, useState } from "react";
import Switch from 'react-switch';

export default function ColorSettings({}) {
    const [darkMode, setDarkMode] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('darkMode')) {
            setDarkMode(true);
        }
    }, [])
    useEffect(() => {
        console.log("Darkomde changed to:", darkMode);
        if (darkMode) {
            localStorage.setItem('darkMode', darkMode);
            document.documentElement.style.setProperty('--background', '#0d0d0d');
            document.documentElement.style.setProperty('--background-1', '#171717');
            document.documentElement.style.setProperty('--background-2', '#212121');
            document.documentElement.style.setProperty('--background-3', '#2f2f2f');
            document.documentElement.style.setProperty('--background-4', '#424242');
            document.documentElement.style.setProperty('--text-icons', '#fff');
            document.documentElement.style.setProperty('--text-icons-1', '#ececec');
            document.documentElement.style.setProperty('--text-icons-2', '#cdcdcd');
            document.documentElement.style.setProperty('--text-icons-3', '#b4b4b4');
            document.documentElement.style.setProperty('--text-icons-4', '#9b9b9b');
        } else {
            localStorage.removeItem('darkMode')
            document.documentElement.style.setProperty('--background', '#fff');
            document.documentElement.style.setProperty('--background-1', '#ececec');
            document.documentElement.style.setProperty('--background-2', '#cdcdcd');
            document.documentElement.style.setProperty('--background-3', '#b4b4b4');
            document.documentElement.style.setProperty('--background-4', '#9b9b9b');
            document.documentElement.style.setProperty('--text-icons', '#0d0d0d');
            document.documentElement.style.setProperty('--text-icons-1', '#171717');
            document.documentElement.style.setProperty('--text-icons-2', '#212121');
            document.documentElement.style.setProperty('--text-icons-3', '#2f2f2f');
            document.documentElement.style.setProperty('--text-icons-4', '#424242');
        }

    }, [darkMode])
    return (
        <div className="subsetting-content">
            <center><h4>Color Settings</h4></center>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                Here you can activate the dark mode of the website. This will be saved as user preference.
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: "15px", width:"30%" }}>
                    <div style={{flex:"1"}}>{darkMode ? "Dark Mode" : "Ligth Mode" }</div>
                    <div style={{flex:"1"}}>
                        <Switch
                            checked={darkMode}
                            onChange={() => {
                                setDarkMode(!darkMode);
                            }}
                        />
                    </div>

                </div>
            </div>

        </div>
    );
}
