import React, { StrictMode, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import './styles/App.css'
import reportWebVitals from './reportWebVitals';
import LoginInterface from './Pages/Login/LoginInterface';
import Home from './Pages/Home/Home';
import SettingsSite from './Pages/Settings/SettingsSite';
import CookieConsent from 'react-cookie-consent';
import { Navigate } from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Impressum from './Pages/Impressum/Impressum';
import APISetup from './Pages/APISetup/APISetup';

function App() {
  const [user, setUser] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [apiURL, setApiURL] = useState(null);
  const [availableLLMAPIs, setAvailableLLMAPIs] = useState(null);
  const [allLLMAPIs, setAllLLMAPIs] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerHeight > window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      const check_mobile = (window.innerHeight > window.innerWidth) || (window.innerWidth<750);
      if (check_mobile !== isMobile) {
        setIsMobile(check_mobile);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile])




  useEffect(() => {
    if(localStorage.getItem("pp_api_url")){
      setApiURL(localStorage.getItem("pp_api_url"))
    }
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode) {
      localStorage.setItem('darkMode', darkMode);
      document.documentElement.style.setProperty('--background', '#0d0d0d');
      document.documentElement.style.setProperty('--background-1', '#171717');
      document.documentElement.style.setProperty('--background-2', '#212121');
      document.documentElement.style.setProperty('--background-3', '#2f2f2f');
      document.documentElement.style.setProperty('--background-4', '#424242');
      document.documentElement.style.setProperty('--text-icons', '#fff');
      document.documentElement.style.setProperty('--text-icons-1', '#ececec');
      document.documentElement.style.setProperty('--text-icons-2', '#cdcdcd');
      document.documentElement.style.setProperty('--text-icons-3', '#b4b4b4');
      document.documentElement.style.setProperty('--text-icons-4', '#9b9b9b');
    } else {
      document.documentElement.style.setProperty('--background', '#fff');
      document.documentElement.style.setProperty('--background-1', '#ececec');
      document.documentElement.style.setProperty('--background-2', '#cdcdcd');
      document.documentElement.style.setProperty('--background-3', '#b4b4b4');
      document.documentElement.style.setProperty('--background-4', '#9b9b9b');
      document.documentElement.style.setProperty('--text-icons', '#0d0d0d');
      document.documentElement.style.setProperty('--text-icons-1', '#171717');
      document.documentElement.style.setProperty('--text-icons-2', '#212121');
      document.documentElement.style.setProperty('--text-icons-3', '#2f2f2f');
      document.documentElement.style.setProperty('--text-icons-4', '#424242');
    }
  }, [])

  useEffect(() => {
    //console.log("API URL changed to:", apiURL)
    checkForPreviousLogin();
    fetchCompanySettings();
  }, [apiURL])

  useEffect(() => {
    if (user && apiURL) {
      fetchAvailableLLMs();
      fetchAllCompanyLLMs();
    }
  }, [user])


  function onLoginSuceeded(logged_user) {
    setUser(logged_user);
  }

  function logOut() {
    setUser(null);
    localStorage.removeItem("token");
    //console.log('Logout');
  }

  async function fetchCompanySettings() {
    if (apiURL) {
      try {
        const response = await fetch(
          apiURL + "/get_company_data",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.status !== 200) {
          console.log("Response status: " + response.status);
        } else {
          const data = await response.json();
          //console.log("Company Data:", data)
          setCompanyData({
            "companyName": data.companyName,
            "twoFaMandatory": data.twoFaMandatory,
            "allowRegistration": data.allowRegistration,
            "pp_api_url": data.pp_api_url,
            "pp_admin_name": data.pp_admin_name,
            "pp_admin_mail": data.pp_admin_mail,
            "hiddenAutoAnonymize": data.hiddenAutoAnonymize
          })
        }
      } catch (error) {
        console.log(error);
        //alert("Error: " + error);
      }
    }
  }

  async function fetchAllCompanyLLMs() {
    if (apiURL) {
      try {
        const response = await fetch(
          apiURL + "/get_all_company_llms",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.status !== 200) {
          console.log("Response status: " + response.status);
        } else {
          const data = await response.json();
          //console.log("Get LLMs Response:", data)
          if (data.all_company_llms) {
            //console.log("Setting LLM APIS in INDEX.JS (fetch LLMs)")
            setAllLLMAPIs(data.all_company_llms.sort((a, b) => a.api_id - b.api_id).map((llm_data) => {
              return {
                "api_id": llm_data.api_id,
                "api_name": llm_data.api_name,
                "api_model_name": llm_data.api_model_name,
                "is_company_api": llm_data.is_company_api,
                "api_url": llm_data.api_url,
                "api_key": llm_data.api_key ? llm_data.api_key : null,
                "security_level": llm_data.security_level,
                "contingentType": llm_data.contingentType,
                "is_alive": llm_data.is_alive,
                "connection_hint": llm_data.connection_hint,
                "icon_number": llm_data.icon_number
              }
            }))
          }
        }
      } catch (error) {
        console.log(error);
        //alert("Error: " + error);
      }
    }
  }

  async function fetchAvailableLLMs() {
    if (apiURL) {
      try {
        const response = await fetch(
          apiURL + "/get_available_llms",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.status !== 200) {
          console.log("Response status: " + response.status);
        } else {
          const data = await response.json();
          //console.log("Get LLMs Response:", data)
          if (data.available_lmms) {
            //console.log("Setting LLM APIS in INDEX.JS (fetch LLMs)")
            setAvailableLLMAPIs(data.available_lmms.map((llm_data) => {
              return {
                "api_id": llm_data.api_id,
                "api_name": llm_data.api_name,
                "api_model_name": llm_data.api_model_name,
                "is_company_api": llm_data.is_company_api,
                "api_url": llm_data.api_url,
                "api_key": llm_data.api_key ? llm_data.api_key : null,
                "security_level": llm_data.security_level,
                "contingentType": llm_data.contingentType,
                "is_alive": llm_data.is_alive,
                "connection_hint": llm_data.connection_hint,
                "icon_number": llm_data.icon_number
              }
            }))
          }
        }
      } catch (error) {
        console.log(error);
        //alert("Error: " + error);
      }
    }
  }




  async function checkForPreviousLogin(navigate_to_home = true) {
    if (apiURL) {
      try {
        const response = await fetch(
          apiURL + "/logged_in",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Cache-Control": "no-cache",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (response.status !== 200) {
          //console.log("Response status: " + response.status);
        } else {
          var data;
          if (!response.headers.get("content-type").includes("application/json")) {
            console.log("Loading User data from Browser cache")
            data = JSON.parse(localStorage.getItem("CACHE_KEY_ACCOUNT_INFO"));
          } else {
            data = await response.json();
            localStorage.setItem("CACHE_KEY_ACCOUNT_INFO", JSON.stringify(data));
          }
          console.log("Setting user with:", data.user)
          setUser(data.user)
          if (data.user && navigate_to_home) {
            //console.log("Redirectiong to home with user");
            return <Navigate to="/" />
          }
        }
      } catch (error) {
        //window.location.href = "/login";
        console.log(error);
        alert("Error: " + error);
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home onLogout={() => {
        logOut();
      }}
        user={user}
        apiURL={apiURL}
        availableLLMs={availableLLMAPIs}
        isMobile={isMobile} />
    },
    {
      path: "/impressum",
      element: <Impressum
        apiUrl={apiURL}
        userData={user}
        companyData={companyData} 
        onLogout={()=>{
          logOut();
        }}
        />
    },
    {
      path: "/api_setup",
      element: <APISetup
        apiUrl={apiURL}
        onApiURLChanged={(newAPIURL) => {
          //console.log("Index setting new API URL:", newAPIURL);
          setApiURL(newAPIURL);
        }}
        onSetupCompleted={() => {
          fetchCompanySettings();
        }}
      />
    },
    {
      path: "/login",
      element: <LoginInterface
        apiURL={apiURL}
        onLoginSuceeded={onLoginSuceeded}
        user={user}
        companyData={companyData} // Use the callback directly here
      />
    },
    {
      path: "/settings",
      element: <SettingsSite
        user={user}
        apiURL={apiURL}
        availableAPIs={availableLLMAPIs}
        allCompanyAPIs={allLLMAPIs}
        onLogout={() => {
          logOut();
        }}
        companyData={companyData}
        onCompanyDataChanged={() => {
          fetchCompanySettings();
        }}
        onUserDataChanged={() => {
          checkForPreviousLogin(false);
        }}
        onAvailableAPIsChanged={() => {
          fetchAvailableLLMs();
          fetchAllCompanyLLMs();
        }}
        isMobile={isMobile}
      />
    },
  ]);
  return (
    <StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider
          router={router}
        />
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myWebsiteCookieConsent"
          style={{ background: "var(--background-3" }}
          buttonStyle={{ color: "var(--text-icons-3)", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </Suspense>
    </StrictMode>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
reportWebVitals();
